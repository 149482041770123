<template>
  <div>
    <b-row class="max-width-1400">
      <b-col class="p-0">
        <b-alert 
          :class="`col-12 p-1 text-left ${getVariantStatus()}`"
          show
          v-if="(is_your_campaign && casting_call.status !== 'published') || (is_your_campaign) && is_editing"
        >
          <b-row class="m-0 w-100">
            <div class="avenir-medium d-flex justify-content-between align-items-center">
              <b-icon icon="exclamation-circle-fill" class="mr-05" font-scale="1.3"></b-icon>
              <div v-if="casting_call.status !== 'in_review' && casting_call.status !== 'changes_required'">
                {{ $t('creator.previewDescrip1Cas') }} <strong>{{ $t('creator.previewDescrip2Cas') }}</strong>{{ $t('creator.previewDescrip3') }}
              </div>
              <div v-else-if="casting_call.status === 'in_review'">
                <span class="d-block avenir-bold">{{ $t('creator.castingSumited') }}</span>
                <span>{{ $t('creator.castingSumitDesc') }}</span>
              </div>
              <div v-else-if="casting_call.status === 'changes_required'">
                <span class="d-block avenir-bold">Admin:</span>
                <span>{{casting_call.changes_admin}}</span>
              </div>
            </div>
         
          </b-row>
        </b-alert>
      </b-col>
    </b-row>

    <b-col class="col-12 p-0 max-width-1400 text-center">
      <b-link @click="redirectLastRoute($router)" class="w-100 d-block text-left mb-2 h5">
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        <strong>
         {{ $t('tracking.goBack') }}
        </strong>
      </b-link>
      <div v-if="is_authenticated && aplied_casting_call.prev_apply && aplied_casting_call.pitch">
        <b-alert 
          class="w-100 p-1 text-left card-mesage-pitch-send"
          show
          v-if="aplied_casting_call.pitch.status === 'submitted'"
        >
          <b-row class="d-flex flex-column">
            <b-col>
              <span>
                <b-img class="svg-icon me-1 flex-shrink-0" :src="require('@/assets/images/icons/congratulations.svg')"></b-img>
                {{ $t('creator.pitchSumited') }}
              </span>
            </b-col>
            <span class="ml-1">{{ $t('creator.mesagePitchSubmited') }}</span>
          </b-row>
        </b-alert>
        <b-alert 
          class="w-100 p-1 text-left"
          variant="danger"
          show
          v-if="aplied_casting_call.pitch.status === 'rejected'"
        >
          <b-row class="m-0">
            <h5 class="w-100">{{$t('creator.message_buyer')}}</h5>
            <strong class="w-100">{{$t('creator.reason')}}: {{getTypeReason()}}</strong>
            <p class="avenir-medium">{{aplied_casting_call.pitch.reject_message}}</p>
          </b-row>
        </b-alert>
      </div>
      <header-preview-casting 
        :data_header="{
          title: casting_call.title,
          cover_image: casting_call.cover_image ? utils.getAssetUrl(casting_call.cover_image) : casting_call.cover_url,
          is_avaible_to_editing
        }" 
        :color="casting_call.main_color"
        @edit_campaign="editCampaign"
      />
      <b-row class="mt-2 m-0">
        <b-col :class="`d-flex flex-wrap ${is_avaible_to_editing ? 'col-12' : 'col-12 col-lg-8'} p-0`">
          <body-preview-casting-call 
            :casting_call="casting_call" 
            :is_avaible_to_editing="is_avaible_to_editing"  
            v-if="loaded"
            @edit_campaign="editCampaign"
          ></body-preview-casting-call>
          <div class="w-100 text-left" v-if="similar_casting_calls.length > 0">
            <h2 class="mb-3 mt-3">{{ similar_casting_calls.length === 1 ? $t('creator.similarCampaign') : $t('creator.similarCampaigns') }}</h2>

            <vue-horizontal responsive class="horizontal my-2" :displacement="0.6">
              <card-casting-call 
                v-for="casting_call in similar_casting_calls" 
                :key="casting_call.uuid" 
                :casting_call="casting_call"
                :redirect="true"
              ></card-casting-call>
            </vue-horizontal>
          </div>
        </b-col>
        
        <div v-if="!is_avaible_to_editing" class="col-12 col-lg-4 p-0 d-lg-block pl-0 pl-lg-1">
          <b-col class="list-overview">
            <b-alert 
              :class="`p-1 text-left warning-alert-proposal w-100 d-flex`"
              show
              v-if="casting_call.location && casting_call.location.length > 0 && casting_call.status !== 'archived'"
            >
              <feather-icon icon="InfoIcon" size="20" class="d-block flex-shrink"></feather-icon> 
              <div class="avenir-medium ml-1">
                {{ $t('creator.thisBrandIs') }} {{ casting_call.location.join(', ') }}. {{ $t('creator.isPossible') }}
              </div>
            </b-alert>
            <b-alert :class="`p-1 text-left warning-alert-proposal w-100 d-flex`" show v-if="casting_call.status === 'archived'">
              <feather-icon icon="InfoIcon" class="d-block flex-shrink" size="20"></feather-icon>
              <div class="avenir-medium ml-1">
                {{ $t('creator.campaignArchived') }}.
              </div>
            </b-alert>
            <div class="d-flex flex-wrap mb-2">
              <h6 class="w-100 text-left">{{getTitlePlatforms()}}</h6>
              <b-badge pill v-for="(type_work, index) in casting_call.work_types" :key="index" :variant="getBadgeNetwork(type_work)" :class="`${getBadgeNetwork(type_work)} badge-networks mr-1 mb-05`">
                <b-img :src="getIconWhite(type_work)" class="img-network" fluid v-if="casting_call.content_type === 'influencer_marketing'"></b-img>
                {{$t(`creator.service_focus_in_${casting_call.content_type}.options`).find((type) => type.value === type_work).text}}
              </b-badge>
            </div>

            <div class="d-flex flex-column align-items-start mb-2" v-if="casting_call.date_delivery"> 
              <h6>{{ $t('creator.dueBy') }}</h6>
              <b-badge pill variant="light-secondary-v2" class="light-secondary-v2">
                <feather-icon icon="CalendarIcon"></feather-icon>
                {{getDatev2(new Date(casting_call.date_delivery))}}
              </b-badge>
            </div>

            <div class="d-flex flex-column align-items-start mb-2">
              <h6>{{ $t('creator.compensations') }}</h6>
              
              <div v-if="casting_call.compensations.length > 0" class="d-flex flex-wrap position-relative">  
                <b-badge pill v-for="(compensation, index) in casting_call.compensations" :key="index" variant="badge-compensation" class="badge-compensation mr-1 mb-1">
                  <b-icon v-if="getCompensation(compensation.type_compensation).icon" class="custom-badge-class" :icon="getCompensation(compensation.type_compensation).icon"></b-icon>
                  <b-img v-else class="icon-svg" :src="IconTypeCompetationGreen(compensation.type_compensation)"></b-img>
                  {{ getCompensation(compensation.type_compensation).text2 }}

                  <span v-if="casting_call.type_compensation === 'fixed'">
                    ($ {{ utils.numberFormat(compensation.amount, true) }} USD) 
                  </span>
                </b-badge>
              </div>
              <div v-else class="d-flex">
                <b-badge pill varinat="outline-secondary-badge" class="outline-secondary-badge">
                  {{ $t('creator.negotiable') }}
                </b-badge>
              </div>
            </div>
            <div v-if="casting_call.status !== 'archived'">
              <div v-if="is_authenticated === null" class="border-top pt-1">
                <p class="avenir-medium">{{$t('creator.interestsInCastingCall')}}</p>
                <b-button variant="blue-button-next-step" @click="messageAction" class="blue-button-next-step mb-2 mb-xl-0 d-none d-lg-block w-100">{{$t('creator.buttonLoginCasting')}}</b-button>
              </div>
  
              <div v-else-if="is_authenticated && aplied_casting_call.prev_apply && aplied_casting_call.pitch" class="border-top">
                <div :hidden="rol !== 'creator'" v-if="aplied_casting_call.pitch.status !== 'draft'">
                  <h4 class="d-flex justify-content-start align-items-end mt-2">{{ $t("creator.submittedPitch") }}</h4>
                  <card-casting-call-submitted :aplied_casting_call="aplied_casting_call"></card-casting-call-submitted>
                </div>
                <div :hidden="rol !== 'creator'"
                  v-else-if="aplied_casting_call.pitch.status === 'draft'"
                >
                  <div class="list-overview">
                    <p>{{$t('creator.progressCastingCall')}}</p>
                    <div class="d-none d-lg-flex justify-content-center">
                      <b-button 
                        variant="primary" 
                        @click="$router.push({ name: 'stepper-send-pitch', params: {slug: aplied_casting_call.pitch.casting_call.slug, step: 'preview', pitch_uuid: aplied_casting_call.pitch.uuid, is_editing: true}})"    
                      >
                      {{$t('creator.finishPitchButton')}}
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
  
              <div v-else-if="is_authenticated" :hidden="rol !== 'creator'" class="border-top">
                <div 
                  class="list-overview" 
                  v-if="current_pitches < is_authenticated.current_plan.pitch_per_month  || is_authenticated.current_plan.pitch_per_month === -1">
                  <p class="avenir-medium">{{$t('creator.interestsInCastingCall')}}</p>
                  <div class="d-none d-lg-flex justify-content-center">
                    <b-button 
                      class="blue-button-send-proposals w-100"  
                      variant="blue-button-send-proposals" 
                      @click="redirectOption()"
                    >
                    {{$t('creator.sendProposalCasting')}}
                    </b-button>
                  </div>
                </div>
                <div class="list-overview" v-else>
                  <p class="avenir-medium">{{$t('creator.unable_send_pitches')}}</p>
                  <div class="d-none d-lg-flex justify-content-center">
                    <b-button variant="primary" @click="openModal">
                      {{$t('creator.see_plans')}}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </div> 
      </b-row>

      <!-- Navs fixed at button -->
      <div class="container-nav-fixed justify-content-center d-lg-none" v-if="casting_call.status !== 'archived' && (!is_avaible_to_editing && !aplied_casting_call.prev_apply) || (aplied_casting_call.pitch && aplied_casting_call.pitch.status === 'draft')">
        <div v-if="is_authenticated">
          <div 
            :hidden="rol !== 'creator'"
            v-if="(aplied_casting_call.prev_apply && aplied_casting_call.pitch) && aplied_casting_call.pitch.status === 'draft'"
          > 
            <b-button 
              variant="primary" 
              @click="$router.push({ name: 'stepper-send-pitch', params: {slug: aplied_casting_call.pitch.casting_call.slug, step: 'preview', pitch_uuid: aplied_casting_call.pitch.uuid, is_editing: true}})"
            >
            {{$t('creator.finishPitchButton')}}
            </b-button>
          </div>
          <b-button 
            class="blue-button-send-proposals"  
            variant="blue-button-send-proposals" 
            @click="redirectOption()"
            v-else-if="current_pitches < is_authenticated.current_plan.pitch_per_month  || is_authenticated.current_plan.pitch_per_month === -1"
          >
          {{$t('creator.sendProposalCasting')}}
          </b-button>
          <b-button v-else-if="current_pitches === is_authenticated.current_plan.pitch_per_month" variant="primary" @click="$router.push({name: 'pages-account-setting', params: { section: 'membership'}})">
            {{$t('creator.see_plans')}}
          </b-button>
        </div>
        <div v-else>
          <b-button variant="blue-button-next-step" class="blue-button-next-step" @click="messageAction">{{$t('creator.buttonLoginCasting')}}</b-button>
        </div>
      </div>

      <!-- Container fixed "Send to review" -->
        <div class="container-nav-fixed" v-if="((is_your_campaign && casting_call.status !== 'published')) || isSuperUser()">
          <div v-if="isSuperUser()" class="d-flex align-items-center justify-content-end w-100 mr-2">
            <b-row>
            <b-form-input
              class="button-free-report"
              v-model="change"
              :placeholder="$t('creator.addComment')"
              />
            </b-row> 
            <b-button :disabled="change.length === 0" variant="danger" class="button-free-report ml-2 mr-1" @click="declineChangeAdmin">{{ $t('creator.decline') }}</b-button>
            <b-button class="button-free-report btn-approve" @click="approveChangeAdmin">{{ $t('creator.approve') }}</b-button>
          </div>
          
          <div v-else class="d-flex align-items-center justify-content-end w-100 mr-2">
            <b-button :disabled="stepsRequired()" variant="blue-button-next-step" class="button-free-report blue-button-next-step" @click="submitReviewAction()">{{ $t('creator.forReview') }}</b-button>
          </div>  
        </div>
      <!-- ------------------------------- -->
        
      <!--  -->
    </b-col>
    <modal-login :open_modal="open_modal_login" :key="update_modal_login"></modal-login>
    <modal-membership-group
      :open_modal_plans_group="open_modal_plans_group" 
      :key="update_modal"
      @close_modal="open_modal_plans_group = false"
      v-if="open_modal_plans_group"
    />
    <sidebar-edit-casting-call
      class="open-sidebar-edit-casting-call"
      :open_sidebar_edit="open_sidebar_edit"
      :key="update_sidebar_edit"
      :section_dad="section_to_edit"
      @update_campaign="updateCampaign"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BAlert,
  BImg,
  BBadge,
  BFormInput,
} from 'bootstrap-vue';
import headerPreviewCasting from '@/views/components/casting-calls/headerPreviewCasting.vue';
import bodyPreviewCastingCall from '@/views/components/casting-calls/bodyPreviewCastingCall.vue';
import cardCastingCall from '@/views/components/casting-calls/cardCastingCall.vue';
import cardCastingCallSubmitted from '@/views/components/casting-calls/cardCastingCallSubmitted.vue';

import VueHorizontal from 'vue-horizontal';
import casting_calls_services from '@/services/casting_calls';
import utils from '@/libs/utils';
import ModalLogin from '@/views/components/modal/ModalLogin.vue';
import { redirectLastRoute } from '@/libs/utils/others'
import { getUserData } from '@/libs/utils/user';
import { IconTypeCompetationGreen, getBadgeNetwork, getIconWhite, getClassNetworkColor } from '@/libs/utils/icons';
import { getDatev2 } from '@/libs/utils/times';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');


export default {
  name: 'previewCasting',
  components: {
    BRow,   
    BCol,
    BButton,
    BLink,
    BAlert,
    BImg,
    BBadge,
    BFormInput,
    headerPreviewCasting,
    cardCastingCall,
    VueHorizontal,
    bodyPreviewCastingCall,
    ModalLogin,
    cardCastingCallSubmitted,
    ModalMembershipGroup: () => import('@/views/components/modal/ModalMembershipGroup.vue'),
    sidebarEditCastingCall: () => import('@/views/components/casting-calls/sidebarEditCastingCall.vue'),
  },
  data() {
    return {
      getIconWhite,
      getBadgeNetwork,
      getClassNetworkColor,
      getDatev2,
      utils,
      rol:'',
      change: '',
      loaded: false,
      similar_casting_calls: [],
      is_preview: this.$route.fullPath.includes('preview'),
      casting_call: {},
      open_edit_modal: false,
      update_edit_modal: false,
      open_modal_login: false,
      update_modal_login: false,
      current_pitches: 0,
      aplied_casting_call: {},
      profileImage: null,
      interval_loading: null,
      open_modal_plans_group: false,
      update_modal: false,
      vanity_name: null,
      redirectLastRoute,
      IconTypeCompetationGreen,
      open_sidebar_edit: false,
      section_to_edit: null,
      update_sidebar_edit: 0
    }
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    is_authenticated() {
      return utils.getUserData()
    },
    is_editing() {
      return this.$route.params.is_editing ? this.$route.params.is_editing : false
    },
    is_your_campaign() {
      if (getUserData()) return getUserData().id === this.casting_call.user;
      return false
    },
    is_avaible_to_editing() {
      return (this.casting_call.status !== 'published' && this.is_your_campaign) || this.is_editing
    }
  },
  created() {
    this.getCastingCallServiceFromParams();
    if (this.is_authenticated) {
      this.getPitchesMonth();
      this.rol = this.is_authenticated ? this.is_authenticated.main_group.name : null;

      if (this.is_authenticated.meta_info && this.is_authenticated.meta_info.vanity_name) {
        this.vanity_name = this.is_authenticated.meta_info.vanity_name;
      }
    }
  },

  methods: {
    redirectOption() {
      if (this.vanity_name) {
        this.$router.push({name: 'stepper-send-pitch', params: {slug: this.slug, step: 'select_pitch_type'}})
      } else {
        this.$router.push({ name: 'my_work', params: { section: 'networks' } }).catch(() => {})
        loadToastificationContent().then((module) => {
          this.$toast({
            component: module.default,
            position: "top-right",
            props: {
              title: this.$t('creator.createProfilePublic'),
              text: this.$t('creator.msjCreateProfilePublic'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });
        })
      }
    },
    updateCampaign(campaign) {
      this.casting_call = campaign;
      this.$route.params.casting_call_data = campaign;
    },
    editCampaign(section) {
      this.section_to_edit = section;
      this.open_sidebar_edit = true; this.update_sidebar_edit += 1;
    },
    openModal() {
      this.open_modal_plans_group = true
      this.update_modal = !this.update_modal;
    },
    stepsRequired() {
      if (this.casting_call.content_type &&
       this.casting_call.work_types.length > 0 &&
       this.casting_call.description !== null &&
       this.casting_call.type_compensation) {
        return false
      } else {
        return true
      }
    },
    editCastingSection() {
      return this.$route.name !== 'preview_casting_call';
    },
    redirectToEditPage() {
      this.is_preview = true;
      this.$router.push({name: 'preview_casting_call', params: {slug: this.casting_call.slug}})
    },
    getVariantStatus() {
      if (this.casting_call.status !== 'in_review' && this.casting_call.status !== 'changes_required') return 'secondary-alert-proposal';
      else if (this.casting_call.status === 'in_review') return 'info-alert-proposal'
      else if (this.casting_call.status === 'changes_required') return 'warning-alert-proposal'
    },
    messageAction() {
      if (!this.is_authenticated) {
        this.open_modal_login = true;
        this.update_modal_login = !this.update_modal_login;
      }
    },
    approveChangeAdmin() {
      casting_calls_services.changeAdmin(this.casting_call.uuid, {status: 'published'}).then((response) => {
        if (response) {
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              position: "top-right",
              props: {
                title: this.$t('creator.titleUnpublished'),
                icon: "BellIcon",
                variant: "success",
                text: this.$t('creator.textApprove'),
              },
            });
          })
        }
        this.$root.$emit('quit_navbar_white');
        this.$router.push({name: 'my_work_brands'});
      })  
    },
    declineChangeAdmin() {
      casting_calls_services.changeAdmin(this.casting_call.uuid, {status: 'changes_required', changes_admin: this.change}).then((response) => {
        if (response) {
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              position: "top-right",
              props: {
                title: this.$t('creator.titleUnpublished'),
                icon: "BellIcon",
                variant: "success",
                text: this.$t('creator.textChange'),
              },
            });
          })
        }
        this.$root.$emit('quit_navbar_white');
        this.$router.push({name: 'my_work_brands'});
      })  
    },
    isSuperUser() {
      return !this.is_authenticated ? false : this.is_authenticated.is_superuser
    },
    updateColor(new_color) {
      this.casting_call.main_color = new_color;
    },
    submitReview() {
      if (this.casting_call.status === 'published') {
        this.$swal({
          title: this.$t('creator.text_confirm_change_casting'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('creator.text_confirm_button_change_casting'),
          cancelButtonText: this.$t('search.cancel'),
          customClass: {
            confirmButton: 'btn confirm-button mb-1',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false,
          heightAuto: false
        }).then(result => {
          if (result.value) {
            this.submitReviewAction();
          }
        });
      } else {
        this.submitReviewAction();
      }
      this.$root.$emit('quit_navbar_white');
    },
    submitReviewAction() {
      casting_calls_services.submitRevisionCastingCall(this.casting_call.uuid).then(() => {
        this.$swal({
          title: this.$t('campaigns.campaignCreate'),
          html: this.$t('campaigns.successCampaign'),
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false,
          heightAuto: false
        })
        this.$router.push({name: 'my_work_brands'});
      });
    },
    getPitchCastingCall(casting_call_uuid) {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });
      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
      casting_calls_services.getPitchApliedCastingCall(casting_call_uuid).then((response => {
        this.aplied_casting_call = response
        setTimeout(() => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        }, 500);
      }));
    },
    getCastingCallServiceFromParams() {
      this.casting_call = this.$route.params.casting_call_data;
      this.loaded = true;
      this.getSimilarCastingCalls();
      if (this.is_authenticated) this.getPitchCastingCall(this.casting_call.uuid);
    },
    getPitchesMonth() {
      casting_calls_services.getPitchesCurrentMonth().then((response) => {
        this.current_pitches = response.pitches_current_month;
      })
    },
    getSimilarCastingCalls() {
      const query = {content_types: [this.casting_call.content_type]}
      casting_calls_services.getCastingCalls(query, 10).then((response) => {
        this.similar_casting_calls = response.results.filter((item) => item.uuid !== this.casting_call.uuid);
      });
    },
    getTitlePlatforms() {
      const types = {
        'influencer_marketing': this.$t('creator.requiredPlatforms'),
        'job_listing': this.$t('creator.typeWork'),
        'work_for_hire': this.$t('creator.requiredTalents'),
      }
      return types[this.casting_call.content_type];
    },
    getCompensation(compensation_value) {
      return this.$t('creator.compensation_type').find((c) => c.value === compensation_value)
    },
    getTypeReason() {
      return this.$t('creator.table_casting_calls.options_reasons').find(i => i.value === this.aplied_casting_call.pitch.reject_reason).label
    }
  }
}
</script>
<style>
.open-sidebar-edit-casting-call > .b-sidebar-right {
  animation: sidebar-edit-proposal 500ms;
}
@keyframes sidebar-edit-proposal {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}
</style>
<style scoped>
.flex-shrink {
  flex-shrink: 0;
}
.img-network {
  margin-right: 0.5em;
  width: 16px;
}
.outline-secondary-badge {
  background-color: white;
  border: 1px solid #ccc;
  color: black;
  padding: 8px;
}
.secondary-alert-proposal {
  background-color: #DEDEDF !important;
  color: #212529 !important;
}
.warning-alert-proposal {
  background-color: #f6e6e6 !important;
  color: #ab3b3b !important;
}
.info-alert-proposal {
  background-color: #e6f2f6 !important;
  color: #3B8DAB !important;
}
.mr-05 {
  margin-right: 0.5em;
}
.badge-networks {
  font-size: small;
  padding: 8px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.icon-svg {
  width: 18px;
  margin: 0px 3px 3px 0px
}
.light-secondary-v2 {
  background-color: #e8e8e8;
  color: #718096;
  font-weight: 400;
  font-size: small;
  padding: 8px;
}
.badge-compensation {
  background-color: rgba(110,205,115,0.1);;
  color: #255E55;
  font-weight: 400;
  font-size: small;
  padding: 8px;
}
.mb-05 {
  margin-bottom: 0.5em;
}
.m-05 {
  margin-right: 5px;
}
.blue-button-send-proposals {
  background: #3483fa !important;
  border: none;
  color: white !important;
}
.blue-button-send-proposals:hover {
  background: #2968c8 !important;
}
.container-nav-fixed {
  width: 100%;
  height: 80px;
  position: fixed;
  background-color: white;
  left: 0;
  bottom: 0;
  padding: 0 6em;
  display: flex;
  justify-content: end;
  align-items: center;
  box-shadow: 0 -2px 5px rgb(0 0 0 / 5%);
  z-index: 10;
}
.svg-icon {
  display: inline-block;
  height: 1.75rem;
  width: 1.75rem;
}
.message-contact-casting {
  margin: 2em 0;
  padding: 1em;
  border: 1px solid #eee5e5;
  background-color: white;
  border-radius: 1em;
  position: sticky;
  position: -webkit-sticky;
  top: 12em;
}
.card-mesage-pitch-send {
  color: #348267 !important;
  background-color: rgba(52,130,103,.15) !important;
}
.container-list-request {
  padding: 1em;
  border: 1px solid #eee5e5;
  border-radius: 1em;
  text-align: left;
  background-color: white;
}
.max-width-1400 {
  max-width: 1400px;
  margin: 0px auto;
}
.h-40 {
  height: 40px;
}
.list-overview {
  padding: 1em;
  border: 1px solid #eee5e5;
  margin: 2em 0;
  border-radius: 1em;
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  top: 7em;
}
.lists-edit-steps {
  padding: 1em;
  border-bottom: 1px solid #dedede;
  display: flex;
  justify-content: space-between;
}
.lists-edit-steps:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.disabled-list-edit {
  cursor: initial !important;
  color: #dedede;
}
.disabled-list-edit:hover {
  background-color: white;
}
.pb-10 {
  padding-bottom: 8em;
}
.btn-approve {
  background-color: #3483fa !important;
  border-color: transparent !important;
}
</style>